import React from 'react';
import { ResourceCenter } from 'cccisd-laravel-resources';
import style from './style.css';

function renderResourceTiles(data, renderDefault) {
    return <div className={style.resourceTiles}>{renderDefault(data)}</div>;
}

const HelpCenter = props => {
    return <ResourceCenter handle="SHAREHelpCenter" renderResourceTiles={renderResourceTiles} />;
};

export default HelpCenter;
